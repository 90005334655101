import css from "./style.module.css";
import PageHead from "../../../Components/PageHead/PageHead";
import { Get } from "../../../Services/Server";
import { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import Url from "../../../Services/Url";
import DataTable from "../../../Components/DataTable/DataTable";
const Reader = (rec) => {
	const exp = rec.expenses;
	const by = exp.submittedBy;
	return [
		"#" + exp.total,
		rec.jobNumber,
		by.firstname + " " + by.lastname,
		rec.location,
		exp.submittedAt,
	];
};

const ExpensesList = ({ navoption }) => {
	const heading = ["Job Number", "Submitted By", "Location", "Submitted on"];
	const Navigate = useNavigate();
	const [current, setCurrent] = useState({
		heading: ["Current Expenses", ...heading],
	});
	const [previous, setPrevious] = useState({
		heading: ["Previous Expenses", ...heading],
	});
	const flex = [20, 20, 20, 20, 20];

	useEffect(() => {
		Get(Url.expenses.enterpriseTasks(false, 0))
			.then((resp) => {
				console.log("fff", resp);

				setCurrent({ ...current, data: resp });
			})
			.catch((err) => {
				console.log(err);
			});
	}, []);

	useEffect(() => {
		Get(Url.expenses.enterpriseTasks(true, 0))
			.then((resp) => {
				setPrevious({ ...previous, data: resp });
			})
			.catch((err) => {
				console.log(err);
			});
	}, []);

	const handleNextCurrent = () => {};
	const onExpenseClick = (e) => {
		//console.log(e)
		Navigate("" + e.id);
	};
	return (
		<div className={css.Page}>
			<PageHead navoption={navoption} title="Expenses"></PageHead>
			<div>
				{current.data && (
					<DataTable
						handlePagination={handleNextCurrent}
						reader={Reader}
						onClick={onExpenseClick}
						flex={flex}
					>
						{current}
					</DataTable>
				)}
			</div>
			<div>
				{previous.data && (
					<DataTable
						handlePagination={handleNextCurrent}
						reader={Reader}
						onClick={onExpenseClick}
						flex={flex}
					>
						{previous}
					</DataTable>
				)}
			</div>
		</div>
	);
};

export default ExpensesList;
