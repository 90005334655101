//const base="http://localhost:8080/api/v1/"
//const base="http://progress.api.fbase.co.uk/api/v1/"
import base from "./base";
//const base="http://localhost:8080/api/v1/";
const company = base + "company/";
const expenses = base + "expenses/";
const sales = base + "sales/";
const client = base + "client/";
const enterpriseClient = base + "enterpriseClient/";
const options = base + "options/";
const upload = base + "upload/";
const img = base + "img/";
const enterprise = base + "enterprise/";
const enterpriseCompany = base + "enterprise-company/";
const contract = base + "contract/";
const order = base + "order/";
const Url = {
	mail: expenses + "mail",
	login: base + "auth/login",
	auth: {
		register: base + "auth/register",
	},
	ui: {
		dashboard: base + "ui/dashboard",
		userTasks: (id) => base + "ui/userTask/" + id,
	},
	client: {
		verify: client + "verify",
		verifyEnterprise: client + "verify-enterprise",
		verifyContract: client + "verify-contract",
		verifyEnterpriseContract: client + "verify-enterprise-contract",
		contact: client + "contact",
		enterpriseContact: client + "enterprise-contact",
		starlinkContact: client + "starlink-contact",
		vessel: client + "vessel",
		starlinkVessel: client + "starlink-vessel",
		port: client + "port",
		getInitial: (id) => client + `initial/get/${id}`,
		signContract: client + "sign-contract",
		signEnterpriseContract: client + "sign-enterprise-contract",
	},
	enterpriseClient: {
		verify: enterpriseClient + "verify",
		verifyContract: enterpriseClient + "verify-contract",
		contact: enterpriseClient + "contact",
		starlinkContact: enterpriseClient + "starlink-contact",
		getEnterpriseInitial: (id) => enterpriseClient + `initial/get/${id}`,
		signContract: enterpriseClient + "sign-contract",
	},
	sales: {
		init: sales + "init",
		enterpriseInit: sales + "enterprise-init",
		starlinkInit: sales + "starlinkInit",
		sendContract: sales + "contract/send",
		sendEnterpriseContract: sales + "enterpriseContract/send",
		getInitDTO: (id) => sales + "initialDTO/get/" + id,
		getAllInitDTO: (current, page) =>
			sales + `initialDTO/get-all/${current}/${page}`,
		getAllEnterpriseInitDTO: (current, page) =>
			sales + `initialDTO/get-all-enterprise/${current}/${page}`,
		getAllMarine: (pending, page) =>
			sales + `initial/get-all-marine/${pending}/${page}`,
		getAllEnterprise: (pending, page) =>
			sales + `initial/get-all-enterprise/${pending}/${page}`,
		getAll: (current, page) => sales + `initial/get-all/${current}/${page}`,
		getInitial: (id) => sales + `initial/get/${id}`,
		getEnterpriseInitial: (id) => sales + `initial/get-enterprise/${id}`,
		saveRequirements: sales + "requirements/save",
		saveEnterpriseRequirements: sales + "enterpriseRequirements/save",
		saveRisk: sales + "risk/save",
		saveEnterpriseRisk: sales + "enterpriseRisk/save",
		getAllRiskItems: sales + "riskItems",
		saveContractBilling: sales + "contract/save-billing",
		saveEnterpriseContractBilling: sales + "enterpriseContract/save-billing",
		saveContractTerms: sales + "contract/save-terms",
		saveEnterpriseContractTerms: sales + "enterpriseContract/save-terms",
		saveContractReview: sales + "contract/save-review",
		saveEnterpriseContractReview: sales + "enterpriseContract/save-review",
	},
	enterprise: {
		dashboard: enterprise + "dashboard",
		userTasks: (id) => enterprise + "userTask/" + id,
		saveEngineer: enterprise + "save-engineer",
		saveTask: enterprise + "save-task",
		saveTaskSales: enterprise + "task/save-sales",
		knowledgeBase: enterprise + "knowledge-base",
		taskList: (current, page) => enterprise + `task-list/${current}/${page}`,
		userTaskList: (current, page) =>
			enterprise + `user-task-list/${current}/${page}`,
		getTask: (id) => enterprise + `get-task/${id}`,
		milestones: enterprise + "milestones",
		saveMilestone: enterprise + "save-milestone",
		milestoneTemplate: enterprise + "milestone-template",
		allTemplates: enterprise + "all-templates",
		saveMilestones: enterprise + "save-milestones",
		templatebyUser: (userid) => enterprise + "user-templates/" + userid,

		// saveMilestone: enterprise + "save-milestone",
		taskCompleted: (id) => enterprise + `task-completed/${id}`,
		taskTemplates: (ownerId, taskId) =>
			enterprise + `task-templates/${ownerId}/${taskId}`,
	},
	enterpriseCompany: {
		allCompany: enterpriseCompany + "get-all",
		get: {
			page: (pg) => enterpriseCompany + "get-page/" + pg,
			logo: (companyId) => enterpriseCompany + "get-logo/" + companyId,
		},
		getCompany: (companyId) => enterpriseCompany + "get/" + companyId,
		availabilityStatus: enterpriseCompany + "availability-status",
		requiredStatus: enterpriseCompany + "required-status",
		clientDesignations: enterpriseCompany + "client-designations",
		clientRoles: enterpriseCompany + "client-roles",
		contacts: (companyId) => enterpriseCompany + "company-contact/" + companyId,
		save: {
			logo: enterpriseCompany + "save-logo",
			company: enterpriseCompany + "save",
		},
	},
	contract: {
		get: (id) => contract + `get-template/${id}`,
		save: contract + "save",
		saveEnterprise: contract + "save-enterprise",
		saveClause: contract + "save-clause",
		getAllClause: contract + "get-all-clause",
		getAllTemplate: contract + "get-all-template",
		getAllEnterpriseTemplate: contract + "get-all-enterprise-template",
		getSelectByTaskType: (taskTypeId) => contract + `get-select/${taskTypeId}`,
		getSelectEnterpriseByTaskType: (taskTypeId) =>
			contract + `get-enterprise-select/${taskTypeId}`,
		saveOrderList: contract + "save-order-list",
	},
	options: {
		periods: options + "periods",
		provisionService: (serviceId) => options + "provision-service/" + serviceId,
	},
	upload: {
		save: upload + "save",
		get: (id) => upload + "get/" + id,
	},
	img: {
		get: (id) => img + "get/" + id,
		thumb: (id) => img + "thumb/" + id,
		getSize: (id, width) => img + "get/" + id + (width ? "/" + width : ""),
	},
	company: {
		all: company + "get-all",
		allDTO: company + "get-all-dto",
		vesselDTO: (companyId) => company + `get-vessel-dto/${companyId}`,
		get: {
			page: (pg) => company + "get-page/" + pg,
			logo: (companyId) => company + "get-logo/" + companyId,
		},
		vesselClasses: company + "vessel-classes",
		vesselTypes: company + "vessel-types",
		vesselWeights: company + "vessel-weights",
		vesselMaterials: company + "vessel-materials",
		vesselPowers: company + "vessel-power",
		availabilityStatus: company + "availability-status",
		requiredStatus: company + "required-status",
		clientDesignations: company + "client-designations",
		clientRoles: company + "client-roles",
		contacts: (companyId) => company + "company-contact/" + companyId,
		save: {
			logo: company + "save-logo",
		},
	},
	expenses: {
		save: expenses + "save",
		saveEnterprise: expenses + "save-enterprise",
		saveApproval: expenses + "save-expense-approval",
		savePayment: expenses + "save-expense-payment",
		get: (id) => expenses + `get/${id}`,
		getEnterprise: (id) => expenses + `get-enterprise/${id}`,
		submit: (id, staffId) => expenses + `submit/${id}/${staffId}`,
		tasks: (approved, page) => expenses + `tasks/${approved}/${page}`,
		enterpriseTasks: (approved, page) =>
			expenses + `enterprise-tasks/${approved}/${page}`,
		approvedTasks: (isPaid, page) =>
			expenses + `approved-tasks/${isPaid}/${page}`,
		approvedEnterpriseTasks: (isPaid, page) =>
			expenses + `approved-enterprise-tasks/${isPaid}/${page}`,
		tasksdto: (approved, page) => expenses + `task-dto/${approved}/${page}`,
		enterpriseTasksdto: (approved, page) =>
			expenses + `enterprise-task-dto/${approved}/${page}`,
	},
	order: {
		saveToInventory: order + "maritime-inventory",
		saveEnterpriseToInventory: order + "enterprise-inventory",
	},
	//company:base+"company/get-all",
	//companyPage:(page)=>base+"company/get-page/"+page,

	countries: base + "staff/countries",
	allStaff: base + "staff/get-all-staff",
	staffWithTask: base + "staff/with-tasks",
	staffAddRole: base + "staff/add-role",
	staffRemoveRole: base + "staff/remove-role",
	RemoveStaff: base + "staff/remove-staff",
	staffWithTaskRole: (rolename) => base + "staff/with-tasks/" + rolename,
	staffByrole: (rolename) => base + "staff/get-role/" + rolename,
	staffById: (id) => base + "staff/" + id,

	selectStaffByRole: (rolename) => base + "select/staff-by-role/" + rolename,
	taskType: base + "task/types",
	taskRange: base + "task/task-range",
	milestones: base + "task/milestones",
	saveMilestones: base + "task/save-milestones",
	saveMilestone: base + "task/save-milestone",
	saveTaskMilestone: base + "task/save-task-milestone",
	deleteMilestone: (id) => base + "task/delete-milestone/" + id,

	milestoneTemplate: base + "task/milestone-template",
	templateByuser: (userid) => base + "task/user-templates/" + userid,
	allTemplate: base + "task/all-templates",
	deleteTemplate: (templateid) => base + "task/delete-template/" + templateid,
	getCompany: (companyId) => base + "company/get/" + companyId,
	vessels: base + "company/vessels",
	saveCompany: base + "company/save",
	taskTemplates: (ownerId, taskId) =>
		base + `task/task-templates/${ownerId}/${taskId}`,
	allEquipment: base + "equipment/all",
	saveTask: base + "task/save",
	saveTaskSales: base + "task/save-sales",
	taskCompleted: base + "task/completed",

	userTasks: (current, page) => base + `task/user-task/${current}/${page}`,
	taskMilestone: base + "task/task-milestone",
	task: (id) => base + `task/${id}`,
	closedTasks: (page) => base + `task/closed/${page}`,
	trashedTasks: (page) => base + `task/trashed/${page}`,
	countTask: (id) => base + `task/count/${id}`,
	getTaskByTracking: (tracking) => base + `task/get-by-tracking/${tracking}`,
	taskByTracking: (tracking) => base + `public/task-by-tracking/${tracking}`,
	saveFbByTracking: (tracking) => base + `public/feedback/${tracking}`,
	publicMilestones: base + `public/milestones`,
	saveReport: base + "report/save",
	saveInitialImages: base + "report/saveInitialImages",
	saveInitialImage: base + "report/saveInitialImage",
	deleteInitialImage: (id) => base + `report/deleteInitialImage/${id}`,
	saveLogImages: base + "report/saveLogImages",
	saveLogImage: base + "report/saveLogImage",
	deleteLogImage: (id) => base + `report/deleteLogImage/${id}`,
	getTaskReport: (taskId) => base + `report/by-task/${taskId}`,
	saveReportLog: base + "report/saveLog",
	getEquipmentReport: (equipmentId) =>
		base + `report/by-equipment/${equipmentId}`,
	getNotifications: (userId) => base + `notification/get/${userId}`,
	getNotification: (id, userId) => base + `notification/get/${id}/${userId}`,
	countNotifications: (userId) => base + `notification/count/${userId}`,
	knowledgeBase: base + `knowledgeBase`,
};
export default Url;
