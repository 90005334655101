import "./TaskDetails.css";
import Milestones from "../../../../Components/Milestones2/Milestones";
import SvgOptions from "../../../../Components/Svg/SvgOptions";
import TaskCompany from "../../../../Components/TaskComany/TaskCompany";
import Executor from "../../../../Components/Executor/Executor";
import Button from "../../../../Components/Button/Button";
import { useLocation, useParams, useNavigate } from "react-router-dom";
import Url from "../../../../Services/Url";
import { Get, Post } from "../../../../Services/Server";
import { useState, useEffect } from "react";
import { toast } from "react-toastify";
import ReportModal from "../../../../Modals/ReportModal/ReportModal";
import ExpenseModal from "../../../../Modals/ExpenseModal/ExpenseModal";
import EquipmentModal from "../../../../Modals/EquipmentModal/EquipmentModal";
import ViewReportWizard from "../../../../Modals/ViewReportModal/ViewReportWizard";
import Head from "../../../../Components/Head/Head";
import JoinOPsModal from "../../../../Modals/JoinOpsModal/JoinOPsModal";
import JoinEngModal from "../../../../Modals/JoinOpsModal/JoinEngModal";
import User from "../../../../Services/User";
import TaskOptionModal from "../../../../Modals/TaskOptionModal/TaskOptionModal";
import DateTime from "../../../../Components/Form/DateTime/DateTime";
import ModalOptions from "../../../../Routes/ModalOptions";
import CloseTaskModal from "../../../../Modals/CloseTaskModal/CloseTaskModal";
const TaskDetails = ({ ModalAction }) => {
	const [task, setTask] = useState();
	const state = useLocation().state;
	const { id } = useParams();
	const role = User.getRole();
	const navigate = useNavigate();

	const handleTrackingClick = () => {
		navigate("/", { state: { tracking: task.tracking } });
	};
	//const {id}=state!=null?state:{id:0};
	const [taskMilestones, setMilestones] = useState([]);
	const uid = User.getBiodata().id;
	const init = () => {
		Get(Url.task(id))
			.then((resp) => {
				setTask(resp);
				const ms = resp.taskEquipmentList.reduce((a, v) => {
					v.taskMilestones.map((x) => (x.equipment = v.name));
					return [...a, ...v.taskMilestones];
				}, []);
				setMilestones(ms);
			})
			.catch((err) => {
				console.log(err);
			});
	};
	useEffect(() => {
		init();
	}, []);
	const onComplete = () => {
		if (task.current) {
			Post(Url.taskCompleted, { id: task.id })
				.then(() => {
					toast("Task completed");
				})
				.catch((err) => {
					console.log(err);
				});
		}
	};
	const closeModal = () => {
		init();
		ModalAction.closeModal();
	};
	const openReport = () => {
		const options = {
			element: ReportModal,
			title: "Job Report",
			id,
			task,
			closeModal,
		};
		ModalAction.openModal(options);
	};

	const openExpense = () => {
		const options = {
			element: ExpenseModal,
			title: "Expenses",
			id,
			task,
			closeModal,
		};
		ModalAction.openModal(options);
	};
	const openEquipment = () => {
		const options = {
			element: EquipmentModal,
			title: "Equipment Info",
			id,
			task,
			closeModal,
		};
		ModalAction.openModal(options);
	};

	const openReportView = () => {
		const options = {
			element: ViewReportWizard,
			title: "Job Report",
			id,
			task,
			closeModal,
		};
		ModalAction.openModal(options);
	};
	const ManageOperations = () => {
		const options = {
			element: JoinOPsModal,
			title: "Manage Operations",
			id,
			task,
			closeModal: () => {
				Get(Url.task(id)).then((resp) => {
					console.log(resp);
					setTask(resp);
					ModalAction.closeModal();
				});
			},
		};
		ModalAction.openModal(options);
	};
	const ManageEngineers = () => {
		const options = {
			element: JoinEngModal,
			title: "Manage Engineers",
			id,
			task,
			closeModal: () => {
				Get(Url.task(id)).then((resp) => {
					console.log(resp);
					setTask(resp);
					ModalAction.closeModal();
				});
			},
		};
		ModalAction.openModal(options);
	};
	const ManageClose = () => {
		const options = {
			element: CloseTaskModal,
			title: "Close Task",
			id,
			task,
			closeModal: () => {
				Get(Url.task(id)).then((resp) => {
					console.log(resp);
					setTask(resp);
					ModalAction.closeModal();
				});
			},
			onClosed: () => {
				ModalAction.closeModal();
				navigate("../");
			},
		};
		ModalAction.openModal(options);
	};

	const isOwner = () => {
		if (role.base === "admin") {
			return true;
		}
		if (task.ownerOperation) {
			return uid === task.ownerOperation.id;
		} else return false;
	};
	const MSAccess = () => {
		if (role.base === "engineer" && task.leadEngineer) {
			return uid === task.leadEngineer.id;
		}
		return isOwner();
	};
	const ManageOptions = () => {
		const options = {
			element: TaskOptionModal,
			title: "Manage Task Options",
			id,
			task,
			closeModal: () => {
				ModalAction.closeModal();
			},
		};
		ModalAction.openModal(options);
	};
	const HandleDelete = () => {
		const options = ModalOptions.confirm;
		options.text = "Are you sure you want to delete this task";
		options.title = "Delete Task";
		options.okText = "Delete";
		options.onOk = () => {
			task.trashed = true;
			Post(Url.saveTask, task).then(() => {
				navigate("../");
			});
		};
		ModalAction.confirm(options);
	};
	const handleRestore = () => {};
	const ddOptions = [
		{ text: "Engineers", onClick: ManageEngineers },
		{ text: "Operations", onClick: ManageOperations },
		{ text: "Options", onClick: ManageOptions },
		{ text: "Close Task", onClick: ManageClose },
	];
	if (role.base === "admin") {
		ddOptions.push({ text: "Delete Task", onClick: HandleDelete });
	}
	const title = (task) => {
		return task.closedTask ? task.title + "[Closed]" : task.title;
	};
	if (task) {
		if (task.closedTask || task.trashed) {
			ddOptions.length = 0;
			ddOptions.push({ text: "Restore", onClick: handleRestore });
		}
	}
	return (
		<>
			{task && (
				<div className="TaskDetails">
					<div className="TDLeft">
						<Head
							title={title(task)}
							dropdown={isOwner() ? ddOptions : []}
						></Head>
						<div className="Main">
							<div className="GeneralTaskInfo">
								<div>
									<div>Job Number</div>
									<div>{task.jobNumber}</div>
								</div>
								<div>
									<div>Tracking Number</div>
									<div>
										<span className="Clickable" onClick={handleTrackingClick}>
											{task.tracking}
										</span>
									</div>
								</div>
								<div>
									<div>Company</div>
									<div>
										<TaskCompany>{task.vessel.company_name}</TaskCompany>
									</div>
								</div>
								<div>
									<div>Ops</div>
									<div>
										{task.taskOperations.map((op) => (
											<Executor size={28} key={op.id}>
												{op.operation}
											</Executor>
										))}
									</div>
								</div>
								<div>
									<div>Engineer</div>
									<div>
										{task.taskEngineers.map((eng) => (
											<Executor size={28} key={eng.id}>
												{eng.engineer}
											</Executor>
										))}
									</div>
								</div>
								<div>
									<div>Vessel Name</div>
									<div>{task.vessel.name}</div>
								</div>
								<div>
									<div>Location</div>
									<div>{task.location}</div>
								</div>
								<div>
									<div>Started on</div>
									<div>{task.createdAt}</div>
								</div>
								<div>
									<div>Chargeable</div>
									<div>
										{task.taskOptions
											? task.taskOptions.chargeable
												? "Yes"
												: "No"
											: "Undefined"}
									</div>
								</div>
								<div>
									<div>First Time Fix</div>
									<div>
										{task.taskOptions
											? task.taskOptions.firstTimeFix
												? "Yes"
												: "No"
											: "Undefined"}
									</div>
								</div>
								{task.closedTask && (
									<>
										<div>
											<div>Reason for closure</div>
											<div>{task.closedTask.details}</div>
										</div>
										<div>
											<div>Closed At</div>
											<div>
												{new Date(task.closedTask.closedAt).toLocaleString()}
											</div>
										</div>
									</>
								)}
							</div>
							<div className="TaskDetailsBtns">
								<div>
									<Button
										onClick={openEquipment}
										icon={SvgOptions.Receipt}
										className="Btn-Mid Inverse"
									>
										Equipment Info
									</Button>
								</div>
								{MSAccess() && (
									<div>
										<Button
											onClick={openReport}
											icon={SvgOptions.Repair}
											className="Btn-Mid Inverse"
										>
											Create Report
										</Button>
									</div>
								)}
								{MSAccess() && (
									<div>
										<Button
											onClick={openExpense}
											icon={SvgOptions.Receipt}
											className="Btn-Mid Inverse"
										>
											Expense Report
										</Button>
									</div>
								)}
								<div>
									<Button
										onClick={openReportView}
										icon={SvgOptions.Receipt}
										className="Btn-Mid Inverse"
									>
										Report
									</Button>
								</div>
							</div>
						</div>
					</div>
					<div className="TDRight">
						{!MSAccess() && (
							<div className="Readonly">
								<h1>Read only</h1>
							</div>
						)}
						<Milestones
							onComplete={onComplete}
							activeUser="engineer"
							updateUrl={Url.taskMilestone}
						>
							{taskMilestones}
						</Milestones>
					</div>
				</div>
			)}
		</>
	);
};
export default TaskDetails;
