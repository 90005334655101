import css from "./style.module.css"
import Expandable from "../../Components/Expandable/Expandable"
import AddOperation from "../../Components/AddEngineer/AddOperation"
import AddEngineer from "../../Components/AddEngineer/AddEnterpriseEngineer"
import { useState } from "react"
import Utils from "../../Services/Utills"
import Svg from "../../Components/Svg/Svg"
import SvgOptions from "../../Components/Svg/SvgOptions"
import Button from "../../Components/Button/Button"
import Url from "../../Services/Url"
import { Post } from "../../Services/Server"
import { toast } from "react-toastify"
const JoinEngModal = ({children}) => {
    const {task, closeModal}=children;
    console.log(children)
    const [expand, setExpand]=useState(true)
    const [Engineers, setEngineers]=useState(
        task.taskEngineers.reduce((a,v, i)=>[...a, {index:i, ...v}],[])
    )
    const [count, setCount]=useState(task.taskEngineers.length)
    
    const onSubmit=(op)=>{
        const dt=Utils.FormObject(op)
        setEngineers(prev=>[...prev, {index:count, ...dt, lead:prev.length===0}]);
        setCount(count+1);
        setExpand(false)
      }
const setLead=(eng)=>{
    setEngineers(Engineers.reduce((a,v)=>[...a,{...v, lead:v===eng}],[]))
}
const handleDelete=(eng)=>{
    const list=Engineers.filter(item=>item.index!==eng.index);
    if(eng.lead && list.length)list[0].lead=true
    setEngineers(list);
}    

const Save=()=>{
    if(Engineers.length===0){
      toast("Cannot save Task without an Engieer");
      return;
    }
    task.taskEngineers=Engineers;
    console.log(task.taskEngineers);
    Post(Url.saveTask, task).then((resp)=>{
        toast("Task Saved");
        closeModal(resp);
    })
}
  return (
    <div className={css.Modal}>
        <Expandable expand={expand} height={130}>
            <AddEngineer onSubmit={onSubmit} />
        </Expandable>

        <div className={css.Body}>
          <div className={css.Operations}>
            {Engineers.map(eng=>
              <EngineerView onDelete={handleDelete} onSetLead={setLead} key={eng.index}>{eng}</EngineerView>
              )}
          </div>
          <div className={css.Controls}>
                <div><Button onClick={Save} className="Btn-Mid Active">Save</Button></div>
          </div>
        </div>     

    </div>
  )
}
const EngineerView=({children, onSetLead, onDelete})=>{
    const {engineer, lead}=children
    return(
      <div className={css.OperationView}>
        <h5>{engineer.name?engineer.name:engineer.fullName}</h5>
        <div className={css.Owner}>
          {
            lead?
            <h5>Lead <Svg className={css.OpsIcon}>{SvgOptions.Ops}</Svg> </h5>:
            <div className={css.BtnLead}><Button className="Btn-Tny Inverse" onClick={()=>onSetLead(children)}>Set Lead</Button></div>
          }
          <Svg onClick={()=>onDelete(children)} className={css.Delete}>{SvgOptions.Delete}</Svg>
        </div>
      </div>
    )
  }
  

export default JoinEngModal